h1 {
    font-size: 90px;
    font-size: clamp(3rem, 8vw, 8rem)
}

.graphic-text {
    font-size: 20px;
    font-weight: bold;
    font-size: clamp(1rem, 2vw, 1.5rem)
}

#evaluate-graphic {
    background: #dda0dd;
    border-radius: 20vh; 
    width: 5vw;
    height: 50vh; 
    display: flex;
}

#grasp-graphic {
    background: #DA70D6;
    border-radius: 20vh;
    width: 5vw;
    height: 50vh;
    display: flex;
}

#apply-graphic {
    background: #BF40BF;
    border-radius: 20vh;
    width: 5vw;
    height: 50vh;
    display: flex;
}

#perfect-graphic {
    background: #800080;
    border-radius: 20vh;
    width: 5vw;
    height: 50vh;
    display: flex;
}

@media (max-width: 768px) {
        .HomePageGraphics__responsive-graphic-size {
                height: 25vh !important;
        }
}