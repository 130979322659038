.textured-background {
    background-color: #f0f0f0;
    background-image: 
      radial-gradient(circle at 10% 20%, #ccc 3%, transparent 3%),
      radial-gradient(circle at 40% 70%, #ccc 1.5%, transparent 1.5%),
      radial-gradient(circle at 90% 10%, #ccc 1.5%, transparent 1.5%),
      radial-gradient(circle at 80% 80%, #ccc 3%, transparent 3%),
      radial-gradient(circle at 50% 50%, #ccc 3%, transparent 3%),
      radial-gradient(circle at 20% 90%, #ccc 2%, transparent 2%),
      radial-gradient(circle at 70% 30%, #ccc 2.5%, transparent 2.5%),
      radial-gradient(circle at 15% 45%, #ccc 1%, transparent 1%),
      radial-gradient(circle at 85% 75%, #ccc 2.5%, transparent 2.5%),
      radial-gradient(circle at 60% 10%, #ccc 3.5%, transparent 3.5%);
    background-size: 50px 50px;
    background-repeat: repeat;
}

.subscribe-form .form-control {
  margin-right: 0.5rem;
}

.subscribe-form .btn-dark {
  background-color: #333;
  color: #fff;
}

.contact-info p {
  text-align: center;
  margin: 0;
}