body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.responsive-text {
  font-size: 1rem; 
  font-size: clamp(0.8rem, 2vw, 1.5rem); 
}

.responsive-text-medium {
        font-size: clamp(0.8rem, 1.3vw, 1.5rem);
}

.responsive-text-small {
  font-size: 16px;
  font-size: clamp(0.7rem, 1vw, 1.5rem);
}

.responsive-text-xsmall {
  font-size: 13px;
  font-size: clamp(0.6rem, 0.9vw, 1.5rem);
}

.responsive-h1 {
  font-size: 32px;
  font-size: clamp(1rem, 4vw, 2.5rem)
}

.responsive-h2 {
  font-size: 28;
  font-size: clamp(0.8rem, 3.2vw, 2rem)
}

.responsive-h4 {
        font-size: clamp(0.8rem, 2vw, 1.5rem)
}