@import url('https://fonts.googleapis.com/css2?family=Signika:wght@400;600;700&display=swap');

.form-title{
    color: #6f0476;
    font-family: 'Signika', sans-serif;
    text-align: center;
}

.form-text{
    font-family: 'signika', sans-serif;
    font-size: 16px;
    font-size: clamp(0.9rem, 1.2vw, 1.5rem);
    padding-bottom: 8px;
}

.signatureCanvas {
    width: 100%;
    height: 200px;
    border: 2px solid #000;
}

@media (min-width: 768px) and (max-width: 991px) {
    .signatureCanvas {
        height: 150px;
    }
}

@media (max-width: 767px) {
    .signatureCanvas {
        height: 100px;
        border: 1px solid #000;
    }
}
