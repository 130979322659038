.img-border {
  border: 5px solid white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}

@media (max-width: 768px) {
        .HomePageAboutFounder_padding {
                margin-top: 0 !important;
        }

        .HomePageAboutFounder_bottom-margins {
                margin-bottom: 0 !important;
        }
}