.header {
  background-color: #5e4675;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;
}

.navbar {
  background-color: #7c5295;
  justify-content: center;
}

.nav-link {
  color: white;
  text-decoration: none;
  margin-right: 20px;
  font-weight: bold;
  text-align: center;
  align-items: center;
}

.Header__responsive-navigation-items {
        font-size: 13px;
        font-size: clamp(0.6rem, 0.9vw, 1.5rem);
}

.dropdown-nav-link {
        color: white;
        text-decoration: none;
        font-weight: bold;
}

.nav-link:hover {
  text-decoration: underline;
  color: white
}

.dropdown-nav-link:hover {
        text-decoration: underline;
        color: white
}

.Header__responsive-h2 {
        font-size: 28;
        font-size: clamp(1.1rem, 3.2vw, 2rem)
}

.branding h1 {
  margin: 0;
  color: white;
}

.branding p {
  margin: 0;
  color: white;
  font-size: 14px;
}

.div-header{
  background-color: #5e4675;
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  align-items: center;
}

.dropdown-toggle {
  background-color: transparent !important;
  /* color: inherit !important;  */
  border: none !important; 
  box-shadow: none !important; 
}

.dropdown-toggle:hover, .dropdown-toggle:focus {
  background-color: transparent;
  color: white
}

.navbar .dropdown:hover .dropdown-menu {
  display: block;
}

.navbar .dropdown-toggle::after {
  display: none;
}

@media (max-width: 768px) {
        .Header__title-margin-bottom {
                margin-bottom: 10px !important;
        }

        .Header__samller-navbar-items {
                font-size: 9.4px !important;
        }

        .Header__no-margin-left {
                margin-left: 5px !important;
        }

        .Header__no-margin-right {
                margin-right: 5px !important;
        }

        .Header__additional-padding-bottom {
                padding-bottom: 4px;
        }
}