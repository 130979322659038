@import url('https://fonts.googleapis.com/css2?family=Signika:wght@400;600;700&display=swap');

.form-title{
    color: #6f0476;
    font-family: 'Signika', sans-serif;
    text-align: center;
}

.workbook-form-title {
    color: #A11060;
    font-family: 'Signika', sans-serif;
    text-align: center;
}

.form-text{
    font-family: 'signika', sans-serif;
    font-size: 16px;
    font-size: clamp(0.9rem, 1.2vw, 1.5rem);
    padding-bottom: 8px;
}

.WorkbooksForm__form-control {
    border-radius: 0;
    width: 100%;
    box-shadow: 6px 6px 0px rgba(0,0,0,0.4);
    border: 1px solid black;
}

.WorkbooksForm__form-check-input {
    border-radius: 0;
    box-shadow: 6px 6px 0px rgba(0,0,0,0.4);
    border: 1px solid red;
    margin-right: 4%;
}

.WorkbooksForm__form-check {
    display: flex;
    align-items: center;
}