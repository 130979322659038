.LiteratureCorner__book-outline {
        border: 3px solid darkgray;
}

.LiteratureCorner__book-outline-margins {
        margin: 10px;
}

@media (max-width: 768px) {
        .LiteratureCorner__responsive-margins {
                margin-right: 4%;
                margin-left: 4%;
        }

        .LiteratureCorner__responsive-title {
                padding-bottom: 10px;
        }

        .LiteratureCorner__responsive-margin-top {
                margin-top: 20px;
        }
}