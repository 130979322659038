.InfoCenter__text-margins {
        padding-left: 5%;
        padding-right: 5%;
}

.InfoCenter__links {
        color: #6f0476;
}

.InfoCenter__text-font {
        font-family: 'Signika', sans-serif;
}

.InfoCenter__div-padding {
        padding-right: 5%;
        padding-left: 5%;
}

.InfoCenter__div-outline {
        outline: 3px solid #87128e;
}