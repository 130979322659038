.Services__th {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 60px;
        padding-left: 60px;
        background-color: #6f0476;
        color: white;
        font-weight: 400;
        text-align: center;
}

.Services__left-column {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 7px;
        padding-left: 7px;
        background-color: #6f0476;
        color: white;
        font-weight: 400;
        text-align: center;
}

.Services__td-1 {
        padding-top: 20px;
        padding-bottom: 20px;
        font-weight: 400;
        background-color: #ededed;
        text-align: center;
}

.Services__td-2 {
        padding-top: 20px;
        padding-bottom: 20px;
        font-weight: 400;
        text-align: center;
}

@media (max-width: 768px) {
        .Services__responsive-margins {
                margin-right: 3% !important;
                margin-left: 3% !important;
        }
}