.ReadingEvaluationForm__form-title {
        color: #6f0476;
        font-family: 'Signika', sans-serif;
        padding-left: 10%;
        padding-right: 10%;
}

.ReadingEvaluationForm__text-margins {
        padding-left: 10%;
        padding-right: 10%;
}